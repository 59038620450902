<template>
    <div class="container" id="UpdateMainEvent">
        <el-page-header @back="$router.back()" content="修改主赛事信息"></el-page-header>
        <div class="UpdateMainEvent_content">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
                <el-form-item label="赛事名称:" prop="name">
                    <el-input v-model.trim="form.name" style="width:100%;max-width:400px"></el-input>
                    <p style="line-height:20px;margin-top:5px;font-size:12px">主赛事名称是指尽量不含有年度、赛季、届、举办地、赞助商等信息的赛事全称</p>
                </el-form-item>
                <el-form-item label="赛事logo:" prop="logoUrl">
                    <div class="uploadImg logo">
                        <div class="img_box">
                            <div class="img" v-show="form.logoUrl || form.preLogoUrl">
                                <img :src="form.logoUrl || form.preLogoUrl">
                                <div class="state"></div>
                            </div>
                            <div class="add">
                                <i class="el-icon-plus"></i>
                                <input type="file" ref="file" @change="imgUpload($event)">
                            </div>
                        </div>
                        <div class="el-upload__tip">只能上传jpg/png格式，建议尺寸400*400像素</div>
                    </div>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px">
                <el-button type="success" style="width:100px" @click="save">修改</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    GetMyMainLeague,
    UpdateMyMainLeague
} from '@/api'
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
export default {
    data(){
        return {
            form:{
                name:"",
                logoUrl:"",
                preLogoUrl:""
            },
            rules:{
                name:[
                    {required:true,message:"请输入赛事名称",trigger: 'blur'}
                ],
                logoUrl:[
                    {required:true,message:"请上传赛事logo"}
                ]
            },
            uploaderOptions:{}
        }
    },
    created(){
        GetMyMainLeague().then(date=>{
            this.form.name = date.MainLeague.Description.Name;
            this.form.logoUrl = date.MainLeague.Description.LogoUrl;
            this.uploaderOptions = date.UploaderOptions;
        })
    },
    methods:{
       imgUpload(event){
            let files = event.target.files, file;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr =  ['image/jpeg','image/png','image/bmp'];
                if(acceptArr.indexOf(file.type) == -1){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (file.size > 100*1024*1024) {
                    this.$message.error('图片大小不能超过 100MB!');
                    return;
                }
                this.ossUpload({
                    fileData: file,
                })
            }
        },
        ossUpload({fileData}){
            var img_src = URL.createObjectURL(fileData);
            var _this = this;
            _this.form.logoUrl = '';
            _this.form.preLogoUrl = img_src;
            var dom = ".logo .state"
            let loading = this.$loading({
                target: dom,
                lock: true,
                text: '图片正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.8)'
            });
            const options = this.uploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition':encodeURIComponent(fileData.name),
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            client.put(nameID, fileData, {headers}).then(function (r1) {
                loading.close();
                _this.form.logoUrl = r1.url;
                _this.form.preLogoUrl = '';
                _this.$refs['form'].validateField(`logoUrl`);
            }).catch(function (err) {
                loading.close();
                _this.form.logoUrl = '';
                _this.form.preLogoUrl = '';
                this.$message.error('图片上传失败，请重试！');
            });
        },
        save(){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    UpdateMyMainLeague({
                        "LeagueName": this.form.name,
                        "LogoUrl": this.form.logoUrl
                    }).then(data=>{
                        if(data.Code == 0){
                            this.$message.success('修改成功，2秒后返回');
                            this.$store.commit('setLeagueInfo',{
                                id: this.$store.state.leagueInfo.id,
                                name: this.form.name,
                                logo: this.form.logoUrl
                            });
                            setTimeout(()=>{
                                this.$router.back();
                            },1500)
                        }
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
</style>